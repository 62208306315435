/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


.text-7xl {
    div {
        font-size: 2.5rem !important;
    }
}

quick-chat {
    display: none;
}

.rtr-table {
    a {
        color: rgb(55 48 163 / var(--tw-text-opacity)) !important;
    }
}

.filter-box {
    float: right;
    width: 300px;

    .mat-form-field {
        width: 100%;
    }
}

.main-layout {
    float: left;
    width: 100%;
    // padding-bottom: 50px;
}

.for-upload-box {
    position: relative;

    input {
        float: left;
        width: 100%;
        height: 100%;
    }

    .file-btn {
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: 2;
        cursor: pointer;
    }

    button {
        position: absolute;
        right: 0;
        border-radius: 0 !important;
        z-index: 1;
        cursor: pointer;
    }
}

.third-width {
    width: 33%;
}

.company-logo {
    display: flex;
    float: left;
    width: 100%;
    align-items: center;

    p {
        font-size: 16px;
        font-weight: 600;
        color: #000;
    }
}

.light-gray-btn {
    background: rgba(0, 0, 0, 0.1) !important;
}

project {
    .tab-pad-0 {
        .mat-tab-group .mat-tab-header .mat-tab-label-container {
            padding: 0;
        }

        .mat-tab-body-content {
            padding: 0;

            .mat-tab-group {
                padding-top: 20px !important;
            }
        }
    }
}

.mat-table {
    th {
        padding-left: 10px !important;
        font-size: 13px;

        &:last-child {
            padding-right: 10px !important;
        }

        .mat-form-field-flex {
            padding: 0 6px !important;
        }
    }

    td {
        position: relative;
        text-align: left;
        padding-left: 10px !important;

        &:last-child {
            padding-right: 10px !important;
        }

        .action-btn {
            position: absolute;
            right: 13%;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;

            button {
                padding: 0 !important;
                margin: 0;
                min-width: auto;
                width: 20px;
            }
        }
    }
}

.footer-width-bottom {
    width: calc(100% - 280px);
}

.mat-menu-panel.xs-v {
    min-width: 80px !important;
    min-height: 40px;

    button {
        min-height: 30px;
        height: 30px;
    }
}

.uni-hi {
    .mat-form-field-flex {
        min-height: 40px !important;
    }
}

.btn-eye {
    background-color: var(--fuse-primary) !important;
    padding: 0;
    max-width: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    max-height: 38px !important;
    border-radius: 3px !important;

    mat-icon {
        color: #fff;
    }
}

.btn-cancel {
    background-color: transparent;
    padding: 0;
    max-width: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    border-radius: 5px !important;

    mat-icon {
        color: rgba(var(--fuse-accent-700-rgb),
                var(--tw-bg-opacity)) !important;
    }
}

.footer-width-bottom {
    z-index: 2;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

@media only screen and (max-width: 768px) {
    .animate__animated.animate__slideInRight {
        width: 100vw !important;
    }

    .footer-width-bottom {
        width: 100%;
    }
}

.green-snackbar {
    background: rgb(34 197 94 / var(--tw-text-opacity)) !important;
    color: white !important;
}

fuse-vertical-navigation {
    z-index: 100 !important;
}

.cdk-overlay-container {
    z-index: 1003 !important;
}

.red-snackbar {
    background: #f44336 !important;
    color: white !important;
}

input[readOnly] {
    color: rgb(178, 186, 195);
}

// fuse-vertical-navigation{
//     z-index: 1001!important;
// }

.mat-option {
    position: relative !important;
}

.mat-optgroup-label {
    display: none !important;
}

.header {
    background: #111725;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
}

.mat-header-cell,
.mat-cell {
    min-width: 80px;
    cursor: auto;
    font-size: 14px;
}

.table-box1 {
    overflow-x: auto;

    .mat-icon {
        color: #111725;
    }

    table {
        width: 100%;

        mat-icon {
            font-size: 20px;
        }

        tr {
            border-bottom: 1px solid #e2e8f0;
            height: 48px;

            &:last-child {
                border-bottom-width: 0;
            }
        }

        th {
            color: #111725 !important;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            min-height: 48px;
            padding: 0 8px;
        }

        td {
            padding: 0 8px;
            color: #1e293b;
            font-size: 14px;
            min-height: 48px;

            .mat-form-field {
                width: 100%;
                max-width: 100%;
                margin-top: 5px;

                .mat-form-field-wrapper {
                    margin-bottom: 0;

                    .mat-form-field-flex {
                        min-height: 35px;

                        input {
                            padding: 10px 0 !important;
                        }
                    }
                }
            }
        }
    }
}

#tabBlock {
    .mat-tab-header .mat-tab-label-container {
        padding: 0;
    }

    .mat-tab-label {
        --tw-bg-opacity: 1 !important;
        min-width: 140px !important;
        background-color: #e9e9e9 !important;
        border-radius: 6px 6px 0 0 !important;
    }

    .mat-tab-label-active {
        background-color: #ffffff !important;
    }

    .mat-tab-body {
        background: #ffffff;
    }
}

.eventpop {
    .mat-dialog-container {
        padding: 0 !important;
        border-radius: 10px !important;
    }
}

#btnPagination {
    .mat-button-toggle {
        margin: 0;
    }

    .mat-button-toggle-button .mat-icon {
        border: 1px solid #f4f5f9;
    }

    .mat-button-toggle-label-content {
        padding: 0;
    }
}

.ql-container {
    height: auto;
}

/* My Css Start */
.preprtmeplate_list {
    display: flex;
}

/* My Css End */
.mat-expansion-panel-header.mat-expanded {
    border-bottom: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity));
    height: 45px;
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

.approval_level_option .mat-form-field-wrapper {
    margin-bottom: 0 !important;
}

/* My Css End */
mat-checkbox label,
.mat-checkbox-label,
.mat-radio-label-content,
mat-radio-button label {
    width: 100%;
}

.danger {
    color: red;
}

td {
    &.no-td-block {
        max-width: inherit !important;

        .mat-form-field-infix {
            max-width: inherit;
            width: inherit !important;

            .mat-select {
                width: auto !important;
            }
        }
    }
}

.mat-paginator-container {
    padding-right: 0 !important;
}

// .mat-placeholder-required{
//     color: #F44336;
// }
#editor {
    .ql-toolbar {
        background: #eeeeee;
        padding: 5px !important;
    }

    .ql-formats {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 3px;
        margin: 5px !important;
    }
}

#editor.redBorder {
    border: 1px solid #dc2626;
    border-radius: 6px;

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
        border: 0;
    }
}

#editor.blueBorder {
    border: 1px solid #1E293B;
    border-radius: 6px;

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
        border: 0;
    }
}

.mat-raised-button {
    min-width: 115px !important;
}

// timepicker css
.timepicker-overlay {
    z-index: 9999 !important;
}

.timepicker-backdrop-overlay {
    z-index: 9999 !important;
}

.timepicker {
    border-radius: 4px !important;

    * {
        font-family: "Inter var" !important;
    }

    input[readOnly] {
        color: #fff !important;
    }
}

.timepicker-dial__container {
    justify-content: center !important;
}

.timepicker__header {
    background: #53b3f9 !important;
    border-bottom: 1px solid var(--ion-color-white);
    padding: 5px !important;
    border-radius: 4px 4px 0 0;
}

.clock-face__number>span.active {
    background-color: #53b3f9 !important;
    color: #ffffff !important;
}

.clock-face__clock-hand {
    background-color: #53b3f9 !important;

    &:after {
        background-color: #53b3f9 !important;
        left: -2.5px !important;
    }
}

.clock-face {
    width: 265px !important;
    height: 265px !important;
    padding: 10px !important;
    box-shadow: 0 1px 5px #9f9c9c;
    background-color: #f4f3f3 !important;
}

.clock-face__clock-hand_minute:before {
    border: 4px solid #53b3f9 !important;
}

.timepicker__actions {
    justify-content: space-between !important;
    padding: 15px 20px 20px !important;
    border-radius: 0 0 4px 4px;

    .timepicker-button {
        background: #53b3f9 !important;
        color: #ffffff !important;
        line-height: 30px !important;
        border-radius: 4px !important;

        span {
            font-weight: 600;
        }
    }

    div:first-child .timepicker-button {
        background: #ffffff !important;
        color: #53b3f9 !important;
        border: 2px solid #53b3f9;
    }
}

.clock-face__number--outer>span {
    color: #000 !important;
    width: 35px !important;
    height: 35px !important;
}

::placeholder {
    font-size: 13px;
}

#tablebtn {
    border: 1px solid #e7e7e7 !important;
    border-radius: 6px;
    height: fit-content;

    .mat-button-toggle-label-content {
        line-height: 15px;
        padding: 6px;
    }
}

.gone-element {
    display: none !important;
}

.invisible-element {
    visibility: hidden;
}

.visible-element {
    display: block !important;
}

.invalid-text-editor {
    border: 2px solid red;
}

.errorColor {
    color: red;
}

.wd-100 {
    width: 100%;
}

.for-back-only {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row-reverse;
}

body.light .mat-raised-button.mat-button-disabled.mat-button-disabled {
    background-color: rgba(148, 163, 184, 0.38) !important;
}


.mat-menu-panel {
    // min-width: 160px !important;
    // max-height: 350px !important;
    // margin: 2px 0 0 0 !important;
    border-radius: 3px;
    background: rgba(var(--fuse-accent-700-rgb), var(--tw-bg-opacity)) !important;

    .mat-menu-item {
        color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
    }
}

.menuheader {
    background: white !important;

    .mat-menu-item {
        color: #1e293b !important;
    }
}

.pagePopup .mat-dialog-container {
    border-radius: 0px !important;
    box-shadow: none !important;
    background-color: rgba(var(--fuse-bg-default-rgb), var(--tw-bg-opacity)) !important;
    overflow-y: auto;

    .pop-padding {
        padding: 0 10px 30px !important;
    }
}

.mat-menu-content .mat-button.mat-flat-button {
    width: 100% !important;
    display: block !important;
    margin: 0 !important;
    text-align: left !important;
}

.form-field-container {
    position: relative;
}

.mat-select-search-no-entries-found{
    padding: 0 0 0 20px !important;
}

.t-expand {
    textarea {
        resize: both !important;
    }

    .mat-form-field-infix {
        width: auto !important;
    }
}

.no-expand {
    textarea {
        resize: none !important;
    }
}

.bill table .uni .mat-form-field-infix textarea {
    resize: both !important;
}

.bill table .uni .mat-form-field-infix {
    width: auto !important;
    min-width: 100px;
}

.mat-sort-header-content {
    text-align: left !important;
    white-space: nowrap;
}

.mat-placeholder-required {
    color: #dc2626 !important;
}

hr {
    border-top: 0;
    margin: 20px 0;
}

.pr-red-snackbar {
    background: #f44336 !important;
    color: white !important;
    white-space: pre-wrap;
}

.pr-green-snackbar {
    background: rgb(34 197 94 / var(--tw-text-opacity)) !important;
    color: white !important;
    white-space: pre-wrap;
}
.blue-readonly{
    .mat-form-field-disabled{
        input,.mat-select-value,textarea{
        color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
        }
    }
}
.white-space-nowrap {
    white-space: nowrap;
}

.mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled{
    .mat-form-field-flex{
        background-color: rgba(0, 0, 0, 0.01) !important;
    }
    opacity: 1 !important;
}

@media only screen and (max-width: 767px) {
    .text-2xl{
        margin-bottom: 15px;
    }
    .col1, .col2{
        position: static !important;
    }
}
.cfield{
    border-radius: 6px;
    padding: 0 16px;
    border-width: 1px;
    --tw-border-opacity: 1 !important;
    border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    min-height: 40px;
}
//  .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text>* {
//     overflow: auto !important;
//     white-space: pre-wrap !important;
//     text-overflow: initial !important;
// }

.approval-dropdown .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix{
    width: auto !important;
}
input[type=time]::after {
    white-space: nowrap !important;
}
/* hide no-input arrow Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

span.text-primary,.hover\:text-primary:hover,i.text-primary,a.text-primary{
    color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
    cursor: pointer;
}

.mat-row:hover,
.mat-cell:hover {
  cursor: pointer;
}

.mat-row:hover {
    background-color: #eeeeee !important; /* Change the background color to your desired highlight color */
    cursor: pointer !important ;
  }
  .context-menu-container{
    width: 300px;
    height: 100px;
    border: 1px solid red;
    background-color: white;
    // position: absolute;
    // left: 0;
    // right: 0;
  }

 .mat-select-search-panel{
overflow-x: auto !important;
    .mat-option{
overflow: visible;
// padding: 0 !important;
&:hover{
    background: none !important;
}
&.contains-mat-select-search{

    .mat-option-text{
        padding: 0 !important;
    }
}
        .mat-option-text{
            overflow: visible;
            padding: 0 16px;
            &:hover{
                background: rgba(148, 163, 184, 0.12);
            }
            ngx-mat-select-search{ position: fixed; z-index: 2; top: 0; left:0}
        }
    }
  }